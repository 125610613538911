<script setup>
import { ref, onMounted } from "vue";
import clientConfig from "@/config/client";
import ProductPageTemplate from "./ProductPageTemplate.vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});

const copy = ref(clientConfig.content.pages.directorsAndOfficers);
</script>

<template>
  <ProductPageTemplate
    :id="copy.id"
    :pageTitle="copy.pageTitle"
    :subHeader="copy.subHeader"
    :mainbody="copy.mainbody"
    :pageName="copy.pageName"
    :teamDivision="copy.teamDivision"
    :contactEmail="copy.contactEmail"
  />
</template>
