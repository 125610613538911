<script setup>
/* eslint-disable */
import axios from "axios";
import { MDBInput, MDBTextarea } from "mdb-vue-ui-kit";

import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onMounted, ref, reactive, computed } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});

const departmentEmails = ref([
  { name: "Property", emailAddress: "property@penunderwriting.com" },
  {
    name: "Liability",
    emailAddress: "gl@penunderwriting.com",
  },
  { name: "Care Liability", emailAddress: "careliability@penunderwriting.com" },
  { name: "Construction", emailAddress: "construction@penunderwriting.com" },
  { name: "Financial Lines", emailAddress: "pr@penunderwriting.com" },
  { name: "Transport", emailAddress: "transport@penunderwriting.com" },
  { name: "Other", emailAddress: "info.au@penunderwriting.com" },
]);

// not sure about this: hl@penunderwriting.com

const getInitialData = () => ({
  department: "",
  subject: null,
  name: null,
  email: null,
  message: null,
});

let contactForm = reactive({
  department: "",
  subject: null,
  name: null,
  email: null,
  message: null,
});

let formSubmitted = ref(null);

const displayNotification = computed(() => {
  return formSubmitted.value ? true : false;
});

const isDev = ["localhost", "www.gbsau.dev"].includes(location.hostname);

const apiUrl = `https://${
  isDev ? "uat" : "prd"
}-de-api.avantek.dev/api/contact`;
const apiJwt = isDev
  ? "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJBU0ZBLUJDLURFViIsInUiOnt9LCJpYXQiOjE3MTAzMDY4OTIsInJvbGVzIjpbIkJyb3dzZXJDbGllbnQiXX0.Szm8aeNgQkQ4wZX2cQ6J3rZwYKfwpr-hzeOqlo4VU_Q"
  : "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJBU0ZBLUJDIiwidSI6e30sImlhdCI6MTcxNzYyODQwOSwicm9sZXMiOlsiQnJvd3NlckNsaWVudCJdfQ.SuULQgOB6-yWgtOklnvXm6wa1CQLGE27bTw5y4FnRDY";

async function sendResponse(e) {
  e.target.classList.add("was-validated");

  let formAnswers = {
    destination: contactForm.department,
    subject: contactForm.subject,
    name: contactForm.name,
    email: contactForm.email,
    message: contactForm.message,
  };

  //  "destination"
  //  "subject"
  //  "name"
  //  "email"
  //  "message"

  try {
    await axios
      .post(apiUrl, formAnswers, {
        headers: { Authorization: `Bearer ${apiJwt}` },
      })
      .then(function (response) {
        if (response.status == 200) {
          formSubmitted.value = true;
        }
      });
  } catch (err) {
    console.log(err);
  }

  e.target.classList.remove("was-validated");

  Object.assign(contactForm, getInitialData());
}
</script>

<template>
  <div>
    <div ref="pageTop"></div>
    <HeaderSection />

    <main id="main">
      <section id="contact-us" class="section">
        <div
          class="side-triangle"
          data-aos="zoom-in"
          data-aos-delay="200"
        ></div>
        <header
          class="header-banner common contact d-flex justify-content-start"
        >
          <h2 class="highlight">Get In Touch<br /></h2>
        </header>
        <div class="container" data-aos="zoom-in">
          <h3 class="highlight">We'd love to hear from you.</h3>

          <div class="row my-5">
            <div class="col-12">
              <h4 class="mb-5">
                Need assistance? Our team of experts is dedicated to providing
                personalised support. Whether you have questions about our
                services or need assistance with a specific underwriting matter,
                we’re here to help. Contact us today to discuss your needs and
                learn how we can assist you.
              </h4>
            </div>
            <div class="col-12 mt-4">
              <h4 class="highlight">Contact details</h4>
              <div class="row">
                <div class="col">
                  <ul class="dept-contacts">
                    <li>
                      <p>Property</p>
                      <a href="mailto:property@penunderwriting.com"
                        >Property@penunderwriting.com</a
                      >
                    </li>
                    <li>
                      <p>General Liability</p>
                      <a href="mailto:gl@penunderwriting.com"
                        >GL@penunderwriting.com</a
                      >
                    </li>
                    <li>
                      <p>Care Liability</p>
                      <a href="mailto:careliability@penunderwriting.com"
                        >Careliability@penunderwriting.com</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="col">
                  <ul class="dept-contacts">
                    <li>
                      <p>Financial Lines</p>
                      <a href="mailto:pr@penunderwriting.com"
                        >PR@penunderwriting.com</a
                      >
                    </li>
                    <li>
                      <p>Transport</p>
                      <a href="mailto:transport@penunderwriting.com"
                        >Transport@penunderwriting.com</a
                      >
                    </li>
                    <li>
                      <p>Construction</p>
                      <a href="mailto:construction@penunderwriting.com"
                        >Construction@penunderwriting.com</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="big-cards-wrapper mt-4">
            <div class="card unit">
              <a
                href="https://maps.app.goo.gl/LB3c44R6atNLiQer7"
                target="_blank"
              >
                <img
                  class="card-img-top"
                  src="@/assets/img/pen/office-sydney.jpg"
                  alt="Pen Underwriting Sydney - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
              /></a>
              <div class="card-body">
                <h5 class="card-title highlight">
                  Sydney<span> - HEAD OFFICE</span>
                </h5>
                <p class="card-text">
                  Level 25, 45 Clarence Street<br />
                  Sydney NSW 2000<br />
                  Australia
                </p>
                <p class="card-text">
                  <b>Tel:</b> <a href="tel:+61293235000">+61 2 9323 5000</a
                  ><br />
                  <b>Email: </b>
                  <a href="mailto:info.au@penunderwriting.com"
                    >info.au@penunderwriting.com</a
                  >
                </p>
              </div>
            </div>
            <div class="card unit">
              <a
                href="https://maps.app.goo.gl/xtVd8HRjMUqhdegt6"
                target="_blank"
              >
                <img
                  class="card-img-top"
                  src="@/assets/img/pen/office-brisbane.jpg"
                  alt="Pen Underwriting Brisbane - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
              /></a>
              <div class="card-body">
                <h5 class="card-title highlight">Brisbane</h5>
                <p class="card-text">
                  Level 12, 157 Ann Street<br />
                  Brisbane QLD 4000<br />
                  Australia
                </p>
                <p class="card-text">
                  <b>Tel:</b> <a href="tel:+61730561400">+61 7 3056 1400</a
                  ><br />
                  <b>Email: </b>
                  <a href="mailto:info.au@penunderwriting.com"
                    >info.au@penunderwriting.com</a
                  >
                </p>
              </div>
            </div>
            <div class="card unit">
              <a
                href="https://maps.app.goo.gl/YwbZ6UEawzRiB8u1A"
                target="_blank"
              >
                <img
                  class="card-img-top"
                  src="@/assets/img/pen/office-melbourne.jpg"
                  alt="Pen Underwriting Melbourne - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                />
              </a>
              <div class="card-body">
                <h5 class="card-title highlight">Melbourne</h5>
                <p class="card-text">
                  Level 3, 333 Collins Street<br />
                  Melbourne VIC 3000<br />
                  Australia
                </p>
                <p class="card-text">
                  <b>Tel:</b> <a href="tel:+61398100600">+61 3 9810 0600</a
                  ><br />
                  <b>Email: </b>
                  <a href="mailto:info.au@penunderwriting.com"
                    >info.au@penunderwriting.com</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <FooterSection />
  </div>
</template>
