<script setup>
import { ref } from "vue";
import clientConfig from "@/config/client";
import TeamElement from "./TeamElement.vue";
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onMounted } from "vue";
import AOS from "aos";
// import _ from "lodash";

onMounted(() => {
  AOS.init();
});

const filterSydney = ref("Sydney");
const filterMelbourne = ref("Melbourne");
const filterBrisbane = ref("Brisbane");
const filterPerth = ref("Perth");
const people = ref(clientConfig.content.people);

const sortedPeople = people;
// const sortedPeople = _.sortBy(people, [function(o) { return o.name; }]);
</script>

<template>
  <div>
    <HeaderSection />

    <main id="main">
      <section id="team" ref="team" class="team section-bg">
        <div
          class="side-triangle"
          data-aos="zoom-in"
          data-aos-delay="200"
        ></div>
        <header class="header-banner common team d-flex justify-content-start">
          <h2 class="highlight">The Team</h2>
        </header>
        <div class="container" data-aos="fade-up">
          <!-- <p class="highlight py-5">
            Partnering with you to create a first-class underwriting service.
          </p> -->

          <h3>Our Insurance <span class="cursive">Underwriters</span></h3>

          <h2 class="highlight my-4">Sydney</h2>
          <div class="underwriters-wrapper row xs-justify-content-start">
            <TeamElement
              v-for="member in sortedPeople"
              :key="member"
              :name="member.name"
              :division="member.division"
              :role="member.role"
              :mobile="member.mobile"
              :mobileUrl="member.mobileUrl"
              :phone="member.phone"
              :phoneUrl="member.phoneUrl"
              :email="member.email"
              :imageUrl="member.imageUrl"
              :location="member.location"
              v-show="member.location == filterSydney"
            />
          </div>

          <h2 class="highlight my-4">Brisbane</h2>
          <div class="underwriters-wrapper row xs-justify-content-start">
            <TeamElement
              v-for="member in sortedPeople"
              :key="member"
              :name="member.name"
              :division="member.division"
              :role="member.role"
              :mobile="member.mobile"
              :mobileUrl="member.mobileUrl"
              :phone="member.phone"
              :phoneUrl="member.phoneUrl"
              :email="member.email"
              :imageUrl="member.imageUrl"
              :location="member.location"
              v-show="member.location == filterBrisbane"
            />
          </div>

          <h2 class="highlight my-4">Melbourne</h2>
          <div class="underwriters-wrapper row xs-justify-content-start">
            <TeamElement
              v-for="member in sortedPeople"
              :key="member"
              :name="member.name"
              :division="member.division"
              :role="member.role"
              :mobile="member.mobile"
              :mobileUrl="member.mobileUrl"
              :phone="member.phone"
              :phoneUrl="member.phoneUrl"
              :email="member.email"
              :imageUrl="member.imageUrl"
              :location="member.location"
              v-show="member.location == filterMelbourne"
            />
          </div>

          <h2 class="highlight my-4">Perth</h2>
          <div class="underwriters-wrapper row xs-justify-content-start">
            <TeamElement
              v-for="member in sortedPeople"
              :key="member"
              :name="member.name"
              :division="member.division"
              :role="member.role"
              :mobile="member.mobile"
              :mobileUrl="member.mobileUrl"
              :phone="member.phone"
              :phoneUrl="member.phoneUrl"
              :email="member.email"
              :imageUrl="member.imageUrl"
              :location="member.location"
              v-show="member.location == filterPerth"
            />
          </div>
        </div>
      </section>
    </main>

    <FooterSection />
  </div>
</template>
