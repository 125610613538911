<script setup>
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});
</script>

<template>
  <div>
    <HeaderSection />

    <main id="main">
      <section id="about-us" class="section about">
        <div
          class="side-triangle"
          data-aos="zoom-in"
          data-aos-delay="200"
        ></div>
        <header class="header-banner common info d-flex justify-content-start">
          <h2 class="highlight">Important Information</h2>
        </header>

        <div class="container" data-aos="zoom-in">
          <div>
            <p>
              If you need to use an interpreter in your interaction with us, you
              can access information and service provided by Automated Telephone
              Interpreting Services (ATIS)
              <a target="_blank" href="https://www.tisnational.gov.au/"
                >https://www.tisnational.gov.au/</a
              >
              1800 131 450
            </p>
            <p>
              If you need to use teletypewriter services in your interaction
              with us, please visit National Relay Service (NRS)
              <a
                target="_blank"
                href="https://www.communications.gov.au/what-we-do/phone/services-people-disability/accesshub/national-relay-service"
                >https://www.communications.gov.au/what-we-do/phone/services-people-disability/accesshub/national-relay-service</a
              >
            </p>
            <p>
              If you have any queries, please contact us at
              <a href="mailto:compliance.au@penunderwriting.com"
                >compliance.au@penunderwriting.com</a
              >
            </p>
            <!-- <h3 class="highlight mt-5">
              Click on the panels below to open relevant documents.
            </h3> -->
          </div>
          <!-- <hr /> -->
          <section>
            <div class="accordion pb-4" id="accordionExample">
              <!-- accoordion item -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    <div class="row important-info-row">
                      <!-- <div class="col col-sm-2 d-none d-sm-block">
                        <img
                          src="@/assets/img/pen/square-img-customer.jpg"
                          alt=""
                          class="cta-round-image-small"
                        />
                      </div> -->
                      <div class="col mt-3 accordion-text">
                        <h5>Supporting Our Customers</h5>
                        <!-- <p>
                          We are committed to assisting & supporting customers
                          who may be vulnerable or affected by family violence
                          or financial hardship.
                        </p> -->
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-Parent="#accordionExample"
                >
                  <div class="accordion-body important-info">
                    <div class="row m-0">
                      <!-- <div class="col col-sm-2 d-none d-sm-block">&nbsp;</div> -->
                      <div class="col docs">
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <div class="docs-item">
                              <img
                                src="@/assets/img/pen/pdf.jpg"
                                class="docs-img"
                                alt="Pen Underwriting - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                              />

                              <div class="docs-content">
                                <a
                                  href="./forms/proposals/important_informations/PEN-CMP006e+Financial+Hardship+Policy+v0324.pdf"
                                  target="_blank"
                                >
                                  <h5>Financial Hardship Policy</h5>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-6">
                            <div class="docs-item">
                              <img
                                src="@/assets/img/pen/pdf.jpg"
                                class="docs-img"
                                alt="Pen Underwriting - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                              />
                              <div class="docs-content">
                                <a
                                  href="./forms/proposals/important_informations/PEN-CMP006f+Financial+Hardship+Application+v0324.pdf"
                                  target="_blank"
                                >
                                  <h5>Financial Hardship Application</h5>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <div class="docs-item">
                              <img
                                src="@/assets/img/pen/pdf.jpg"
                                class="docs-img"
                                alt="Pen Underwriting - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                              />
                              <div class="docs-content">
                                <a
                                  href="./forms/proposals/important_informations/PEN-CMP007e+Family+Violence+and+Supporting+Vulnerable+Customers+Policy+v0324.pdf"
                                  target="_blank"
                                >
                                  <h5>
                                    Family Violence and Support Vulnerable
                                    Customers Policy
                                  </h5>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- accoordion item -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <div class="row important-info-row">
                      <div class="col mt-3 accordion-text">
                        <h5>Supporting Our Brokers</h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-Parent="#accordionExample"
                >
                  <div class="accordion-body important-info">
                    <div class="row m-0">
                      <div class="col docs">
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <div class="docs-item">
                              <img
                                src="@/assets/img/pen/pdf.jpg"
                                class="docs-img"
                                alt="Pen Underwriting - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                              />
                              <div class="docs-content">
                                <h5>TOBA (to be added)</h5>
                              </div>
                            </div>
                          </div>
                          <div class="col"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- accoordion item -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <div class="row important-info-row">
                      <!-- <div class="col col-sm-2 d-none d-sm-block">
                        <img
                          src="@/assets/img/pen/square-img-conduct.jpg"
                          alt=""
                          class="cta-round-image-small"
                        />
                      </div> -->
                      <div class="col mt-3 accordion-text">
                        <h5>Code of Conduct</h5>
                        <!-- <p>
                          We operate under the General Insurance Code of Conduct
                        </p> -->
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-Parent="#accordionExample"
                >
                  <div class="accordion-body important-info">
                    <div class="accordion-body important-info">
                      <div class="row m-0">
                        <!-- <div class="col col-sm-2 d-none d-sm-block">&nbsp;</div> -->
                        <div class="col docs">
                          <div class="row">
                            <div class="col-12 col-md-6">
                              <!-- <div class="docs-item">
                                <img
                                  src="@/assets/img/pen/pdf.jpg"
                                  class="docs-img"
                                  alt=""
                                />
                                <div class="docs-content">
                                  <a
                                    target="_blank"
                                    href="./forms/proposals/important_informations/Retail+Client+Advice+Statement.pdf"
                                  >
                                    <h5>RETAIL CLIENT ADVICE STATEMENT</h5>
                                  </a>
                                </div>

                              </div> -->
                              <div class="docs-item">
                                <img
                                  src="@/assets/img/pen/pdf.jpg"
                                  class="docs-img"
                                  alt=""
                                />
                                <div class="docs-content">
                                  <a
                                    href="./forms/proposals/important_informations/Whistleblower+Policy+v0924.pdf"
                                    target="_blank"
                                  >
                                    <h5>WHISTLEBLOWER POLICY</h5>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="docs-item">
                                <img
                                  src="@/assets/img/pen/pdf.jpg"
                                  class="docs-img"
                                  alt=""
                                />
                                <div class="docs-content">
                                  <a
                                    target="_blank"
                                    href="http://codeofpractice.com.au/"
                                  >
                                    <h5>GENERAL INSURANCE CODE OF PRACTICE</h5>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 col-md-6">
                              <!-- <div class="docs-item">
                                <img
                                  src="@/assets/img/pen/pdf.jpg"
                                  class="docs-img"
                                  alt=""
                                />
                                <div class="docs-content">
                                  <a
                                    href="./forms/proposals/important_informations/Product+Design+and+Distribution+Policy+v0921.pdf"
                                    target="_blank"
                                  >
                                    <h5>
                                      PRODUCT DESIGN AND DISTRIBUTION POLICY
                                    </h5>
                                  </a>
                                </div>

                              </div> -->
                            </div>
                            <div class="col-12 col-md-6">
                              <!-- <div class="docs-item">
                                <img
                                  src="@/assets/img/pen/pdf.jpg"
                                  class="docs-img"
                                  alt=""
                                />
                                <div class="docs-content">
                                  <a
                                    href="./forms/proposals/important_informations/PEN+HDI+Commercial+Motor+Target+Market+Determination+v0124.pdf"
                                    target="_blank"
                                  >
                                    <h5>
                                      PEN HDI COMMERCIAL MOTOR TARGET MARKET
                                      DETERMINATION
                                    </h5>
                                  </a>
                                </div>

                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- accoordion item -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <div class="row important-info-row">
                      <!-- <div class="col col-sm-2 d-none d-sm-block">
                        <img
                          src="@/assets/img/pen/square-img-complaints.jpg"
                          alt=""
                          class="cta-round-image-small"
                        />
                      </div> -->
                      <div class="col mt-3 accordion-text">
                        <h5>Complaints</h5>
                        <!-- <p>Complaints Handling policies</p> -->
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-Parent="#accordionExample"
                >
                  <div class="accordion-body important-info">
                    <div class="row m-0">
                      <!-- <div class="col col-sm-2 d-none d-sm-block">&nbsp;</div> -->
                      <div class="col docs">
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <div class="docs-item">
                              <img
                                src="@/assets/img/pen/pdf.jpg"
                                class="docs-img"
                                alt="Pen Underwriting - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                              />
                              <div class="docs-content">
                                <a
                                  href="./forms/proposals/important_informations/PEN-CMP004e+Complaints+and+Dispute+Resolution+Policy+v1123+Approved+202311.pdf"
                                  target="_blank"
                                >
                                  <h5>
                                    COMPLAINTS AND DISPUTE RESOLUTION POLICY
                                  </h5>
                                </a>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-12 col-md-6">
                            <div class="docs-item">
                              <img
                                src="@/assets/img/pen/pdf.jpg"
                                class="docs-img"
                                alt="Pen Underwriting - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                              />
                              <div class="docs-content">
                                <a
                                  target="_blank"
                                  href="./forms/proposals/important_informations/Lloyds+Underwriters+Complaints+Policy+v0222.pdf"
                                >
                                  <h5>
                                    HDI GLOBAL SPECIALTY COMPLAINTS HANDLING
                                    POLICY
                                  </h5>
                                </a>
                              </div>
                            </div>
                          </div> -->
                        </div>
                        <div class="row">
                          <!-- <div class="col-12 col-md-6">
                            <div class="docs-item">
                              <img
                                src="@/assets/img/pen/pdf.jpg"
                                class="docs-img"
                                alt="Pen Underwriting - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                              />
                              <div class="docs-content">
                                <a
                                  href="./forms/proposals/important_informations/Lloyds+Underwriters+Complaints+Policy+v0222.pdf"
                                  target="_blank"
                                >
                                  <h5>
                                    LLOYD’S UNDERWRITERS COMPLAINTS HANDLING
                                    POLICY
                                  </h5>
                                </a>
                              </div>
                            </div>
                          </div> -->
                          <!-- <div class="col-12 col-md-6">
                            <div class="docs-item">
                              <img
                                src="@/assets/img/pen/pdf.jpg"
                                class="docs-img"
                                alt="Pen Underwriting - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                              />
                              <div class="docs-content">
                                <a
                                  href="./forms/proposals/important_informations/Swiss+Re+International+SE+Australia+Branch+Complaints+Policy.pdf"
                                  target="_blank"
                                >
                                  <h5>SWISS RE COMPLAINTS HANDLING POLICY</h5>
                                </a>
                              </div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- accoordion item -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <div class="row important-info-row">
                      <!-- <div class="col col-sm-2 d-none d-sm-block">
                        <img
                          src="@/assets/img/pen/square-img-privacy.jpg"
                          alt=""
                          class="cta-round-image-small"
                        />
                      </div> -->
                      <div class="col mt-3 accordion-text">
                        <h5>Privacy</h5>
                        <!-- <p>
                          It is of utmost importance to us that personal or
                          sensitive information you provide to us is not used
                          for any other purpose than that intended and expected
                          by you.
                        </p> -->
                        <!-- <p class="highlight">
                          A full copy of our Privacy Policy Statement is
                          available here:
                        </p> -->
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-Parent="#accordionExample"
                >
                  <div class="accordion-body important-info">
                    <div class="row m-0">
                      <!-- <div class="col col-sm-2 d-none d-sm-block">&nbsp;</div> -->
                      <div class="col docs">
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <div class="docs-item">
                              <img
                                src="@/assets/img/pen/pdf.jpg"
                                class="docs-img"
                                alt="Pen Underwriting - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                              />
                              <div class="docs-content">
                                <a
                                  href="./forms/proposals/important_informations/PEN-CMP005e+Privacy+Notice+incl+Addendum+v0124+Approved.pdf"
                                  target="_blank"
                                >
                                  <h5>PRIVACY NOTICE</h5>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- accoordion item -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    <div class="row important-info-row">
                      <!-- <div class="col col-sm-2 d-none d-sm-block">
                        <img
                          src="@/assets/img/pen/square-img-whistleblower.jpg"
                          alt=""
                          class="cta-round-image-small"
                        />
                      </div> -->
                      <div class="col mt-3 accordion-text">
                        <h5>Product Governance</h5>
                        <!-- <p>
                          We encourage open and honest dealings between staff, our clients, our service providers and all people we connect with.
                        </p> -->
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-Parent="#accordionExample"
                >
                  <div class="accordion-body important-info">
                    <div class="row m-0">
                      <!-- <div class="col col-sm-2 d-none d-sm-block">&nbsp;</div> -->
                      <div class="docs">
                        <!-- <div class="col-12">
                          <div class="docs-item">
                            <img
                              src="@/assets/img/pen/pdf.jpg"
                              class="docs-img"
                              alt=""
                            />
                            <div class="docs-content">
                              <a
                                href="./forms/proposals/important_informations/Whistleblower+Policy+v0722.pdf"
                                target="_blank"
                              >
                                <h5>WHISTLEBLOWER POLICY</h5>
                              </a>
                            </div>
                          </div>
                        </div> -->

                        <div class="col-12">
                          <div class="docs-item">
                            <img
                              src="@/assets/img/pen/pdf.jpg"
                              class="docs-img"
                              alt=""
                            />
                            <div class="docs-content">
                              <a
                                href="./forms/proposals/important_informations/Product+Design+and+Distribution+Policy+v0824.pdf"
                                target="_blank"
                              >
                                <h5>PRODUCT DESIGN AND DISTRIBUTION POLICY</h5>
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="docs-item">
                            <img
                              src="@/assets/img/pen/pdf.jpg"
                              class="docs-img"
                              alt=""
                            />
                            <div class="docs-content">
                              <a
                                href="./forms/proposals/important_informations/PEN+HDI+Commercial+Motor+Target+Market+Determination+v0124.pdf"
                                target="_blank"
                              >
                                <h5>
                                  PEN HDI COMMERCIAL MOTOR TARGET MARKET
                                  DETERMINATION
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- accoordion item -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSeven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    <div class="row important-info-row">
                      <!-- <div class="col col-sm-2 d-none d-sm-block">
                        <img
                          src="@/assets/img/pen/square-img-broker.jpg"
                          alt=""
                          class="cta-round-image-small"
                        />
                      </div> -->
                      <div class="col mt-3 accordion-text">
                        <h5>Social Responsibility & Sustainability</h5>
                        <!-- <p>
                          We are committed to assisting brokers to maintain high
                          standards of professional practice in their dealings
                          with clients and each other, in line with the National
                          Insurance Brokers Association (NIBA) .
                        </p> -->
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-Parent="#accordionExample"
                >
                  <div class="accordion-body important-info">
                    <div class="row m-0">
                      <!-- <div class="col col-sm-2 d-none d-sm-block">&nbsp;</div> -->
                      <div class="col docs">
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <div class="docs-item">
                              <img
                                src="@/assets/img/pen/pdf.jpg"
                                class="docs-img"
                                alt="Pen Underwriting - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                              />
                              <div class="docs-content">
                                <a
                                  target="_blank"
                                  href="./forms/proposals/important_informations/Pen+ESG+Statement+v0824.pdf"
                                >
                                  <h5>ESG Statement</h5>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end accordion item -->
          </section>

          <div></div>
          <div
            class="justify-content-top pt-4 pt-lg-0 order-2 order-lg-2 mt-5 gap-4 quick-link-wrapper"
          ></div>
        </div>
        <!-- CLARIFY NEXT SECTION -->
      </section>
    </main>

    <FooterSection />
  </div>
</template>
