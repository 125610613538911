import { createRouter, createWebHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";
import HomePage from "../components/HomePage.vue";
import Team from "../components/TeamPage.vue";
// import ProductsAndServices from "../components/ProductsAndServices.vue";
import RecentWins from "../components/RecentWins.vue";
import AboutUs from "../components/AboutUs.vue";
import ContactUs from "../components/ContactUs-backup.vue";
import ContactUsForm from "../components/ContactUs.vue";
// import PrivacyNotice from "../components/PrivacyNotice.vue";
// import SignUp from "../components/SignUp.vue";
import ClaimsPage from "../components/ClaimsPage.vue";
// import ComplainPolicy from "../components/ComplainPolicy.vue";
import ImportantInfo from "../components/ImportantInfo.vue";
import CareerPage from "../components/CareerPage.vue";

import PropertyInsurance from "../components/products/PropertyInsurance.vue";
import GeneralLiability from "../components/products/GeneralLiability.vue";
// import HospitalityLiability from "../components/products/HospitalityLiability.vue";
import CareLiability from "../components/products/CareLiability.vue";
import ConstructionInsurance from "../components/products/ConstructionInsurance.vue";
import ProfessionalRisks from "../components/products/ProfessionalRisks.vue";
import TransportInsurance from "../components/products/TransportInsurance.vue";
import DirectorsAndOfficers from "../components/products/DirectorsAndOfficers.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/team",
    name: "team",
    component: Team,
  },
  {
    path: "/recent-wins",
    name: "recent-wins",
    component: RecentWins,
  },
  {
    path: "/about-pen",
    name: "about-pen",
    component: AboutUs,
  },
  {
    path: "/contact",
    name: "contact us",
    component: ContactUs,
  },
  {
    path: "/contact-form",
    name: "contact form",
    component: ContactUsForm,
  },
  {
    path: "/claims",
    name: "claims",
    component: ClaimsPage,
  },
  {
    path: "/career",
    name: "career",
    component: CareerPage,
  },
  {
    path: "/important-information",
    name: "important information",
    component: ImportantInfo,
  },
  {
    path: "/property-overview",
    name: "property overview",
    component: PropertyInsurance,
  },
  {
    path: "/general-liability-overview",
    name: "general liability overview",
    component: GeneralLiability,
  },
  {
    path: "/hospitality-liability-overview",
    name: "hospitality liability overview",
    component: GeneralLiability,
  },
  {
    path: "/overview-care-liability",
    name: "overview care liability",
    component: CareLiability,
  },
  {
    path: "/construction-overview",
    name: "construction overview",
    component: ConstructionInsurance,
  },
  {
    path: "/professional-risks",
    name: "professional risks",
    component: ProfessionalRisks,
  },
  {
    path: "/transport-overview",
    name: "transport overview",
    component: TransportInsurance,
  },
  {
    path: "/directors-and-officers",
    name: "directors and officers",
    component: DirectorsAndOfficers,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      return new Promise((resolve) => {
        resolve({ top: 0, left: 0 });
      });
    }
  },
});

export default router;
