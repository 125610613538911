<script setup>
import { ref, onBeforeMount, onMounted } from "vue";
import clientConfig from "@/config/client";
import ProductPageTemplate from "./ProductPageTemplate.vue";
import AOS from "aos";

import { useHead } from "@vueuse/head";

const siteData = {
  title: `Care Liability - PEN UNDERWRITING`,
  url: `https://www.penunderwriting.com.au/#/overview-care-liability`,
  description: `We offer insurance expertise combined with tailoring the needs of the aged & disability care sector with superior coverage options across a range of products.`,
};

onBeforeMount(() => {
  useHead({
    // Update Title and Meta
    title: siteData.title,
    meta: [
      { name: `description`, content: siteData.description },
      { name: `twitter:description`, content: siteData.description },
      { name: `twitter:title`, content: siteData.title },
      { name: `twitter:url`, content: siteData.url },
      { property: `og:site_name`, content: siteData.title },
      { property: `og:title`, content: siteData.title },
      { property: `og:url`, content: siteData.url },
      {
        property: `og:description`,
        content: siteData.description,
      },
      { itemprop: `name`, content: siteData.title },
      { itemprop: `url`, content: siteData.url },
      {
        itemprop: `description`,
        content: siteData.description,
      },
    ],
  });
});

onMounted(() => {
  AOS.init();
});

const copy = ref(clientConfig.content.pages.careLiability);
</script>

<template>
  <ProductPageTemplate
    :id="copy.id"
    :pageTitle="copy.pageTitle"
    :subHeader="copy.subHeader"
    :mainbody="copy.mainbody"
    :pageName="copy.pageName"
    :teamDivision="copy.teamDivision"
    :contactEmail="copy.contactEmail"
  />
</template>
