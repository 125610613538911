/* Vue 3 Version */

let _lastHeight = 0;
let _appDiv = null;

const _debounce = function (func, timeout = 50) {
  let delay;
  return (...args) => {
    clearTimeout(delay);
    delay = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

const _resizeIframeMsg = function () {
  setTimeout(() => {
    const currentHeight = parseInt(
      getComputedStyle(document.getElementById(_appDiv), null).height
    );
    console.log(currentHeight);
    if (_lastHeight != currentHeight) {
      parent.postMessage({ type: "resize", height: currentHeight }, "*");
      _lastHeight = currentHeight;
    }
  }, 300);
};

const LifetimeIframeControl = {
  install(Vue, options) {
    //console.log(options) // Only enable this if debugging
    const clientConfig = options.clientConfig.settings;
    _lastHeight = 0;
    _appDiv = options.divId;

    if (typeof clientConfig.disableIframePostMessage == "undefined") {
      Vue.disableIframePostMessage = false;
    } else {
      Vue.disableIframePostMessage = clientConfig.disableIframePostMessage;
    }

    try {
      // iFrame Detection code
      Vue.isInIframe = window.self != window.top;
    } catch (e) {
      Vue.isInIframe = true;
    }

    //try { // Alternate iFrame Detection code
    //	window.top._top = true;
    //	Vue.isInIframe = !window._top;
    //} catch(e) {
    //	Vue.isInIframe = true;
    //}

    if (!Vue.isInIframe) {
      Vue.disableIframePostMessage = true;
    }

    if (!Vue.disableIframePostMessage) {
      const resizeMsgDebounced = _debounce(_resizeIframeMsg);

      const iframeResize = new ResizeObserver(() => {
        resizeMsgDebounced();
      });

      iframeResize.observe(document.querySelector("body"));
    }

    Vue.config.globalProperties.$scrollIframeTop = function () {
      if (!Vue.disableIframePostMessage) {
        //console.log('Triggering Scroll');
        parent.postMessage({ type: "scrolltop", behavior: "smooth" }, "*");
      }
    };
  },
};

export default LifetimeIframeControl;
// this.$refreshIframeHeight();
// this.$scrollIframeTop();
