<script setup>
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});
</script>

<template>
  <div>
    <HeaderSection />

    <main id="main">
      <section id="about-us" class="section about">
        <div
          class="side-triangle"
          data-aos="zoom-in"
          data-aos-delay="200"
        ></div>
        <header
          class="header-banner common career d-flex justify-content-start"
        >
          <h2 class="highlight">Career</h2>
        </header>
        <div class="container pb-5" data-aos="zoom-in">
          <div class="row">
            <div class="col-md-9">
              <h2 class="highlight pb-4">At Pen, our people come first</h2>
              <p>
                We are constantly seeking talented people to join our team,
                whether they are experienced professionals, or just starting out
                in their careers. Our employees are the driving force behind our
                success, serving our brokers and delivering results for our
                security partners.
              </p>
              <p>
                In return for your dedication, we offer a supportive work
                environment that promotes respect, collaboration, innovation,
                and professional development. We also provide outstanding
                benefits and opportunities for career advancement. These
                benefits include competitive salary and incentives, training and
                development programs, tuition assistance, various well-being
                initiatives such as additional time off, a highly regarded
                employee assistance program, salary continuance insurance, paid
                volunteering days, and much more.
              </p>
            </div>
          </div>

          <!-- <hr class="my-5" /> -->

          <!--
          <h3>Available position</h3>

          <div class="row">
            <div class="col-lg-12">
              <ul id="docs-flters">
                <li>Sydney</li>
                <li>Melbourne</li>
                <li>Brisbane</li>
              </ul>
            </div>
          </div> -->

          <!-- job listing SECTION -->

          <div class="row">
            <div class="col-md-9">
              <div class="card mt-5">
                <div class="card-body">
                  <h3 class="highlight pb-4">Work with us</h3>
                  <h4 class="pb-4">
                    If you are seeking a workplace that embodies these values
                    and offers these opportunities, we encourage you to apply
                    now!
                  </h4>

                  <a
                    class="my-4 purple-button"
                    target="_blank"
                    href="https://www.seek.com.au/job/77575821?ref=search-standalone&type=standout&origin=jobTitle#sol=3f8a06fec8b5106c41b2e6b32b1056c45381cddd"
                    >Open roles</a
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card mt-5">
            <div class="card-body">
              <h3 class="highlight pb-4">Senior Underwriter - Transport</h3>
              <h5>2 July 2024</h5>
              <p>
                We understand that at the centre of each claim are our valued
                clients. No two claims are the same, which is why our team of
                claims experts will work with you from beginning to end to
                understand your circumstantial needs.
              </p>
              <p>
                Taking the time to get to know your situation and asking the
                right questions in order to best handle your claim during what
                can be a stressful time is a priority to us at Pen.
              </p>

              <h4>Team Leader Name</h4>
              <p><b>Tel: </b>+61 7 3056 1400</p>
              <p><b>Email:</b> info.au@penunderwriting.com</p>
            </div>
          </div> -->

          <!-- job listing SECTION -->
        </div>
      </section>
    </main>

    <FooterSection />
  </div>
</template>
