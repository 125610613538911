import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import clientConfig from "/src/config/client.js";
import LifetimeIframeControl from "/src/config/LifetimeIframeControl.js";
import LifetimeHelpers from "/src/config/LifetimeHelpers.js";
import "aos/dist/aos.css";
import VueGtag from "vue-gtag";
// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap and BootstrapVue CSS files (order is important)
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "mdb-vue-ui-kit/css/mdb.min.css";

// import "/src/assets/vendor/bootstrap/css/bootstrap.min.css";
import "/src/assets/vendor/icofont/icofont.min.css";
import "/src/assets/vendor/boxicons/css/boxicons.min.css";
import "/src/assets/vendor/remixicon/remixicon.css";
import "/src/assets/vendor/venobox/venobox.css";
import "/src/assets/vendor/owl.carousel/assets/owl.carousel.min.css";
import "animate.css";

import { createHead } from "@vueuse/head";
const head = createHead();

const app = createApp(App);

app
  .use(store)
  .use(head)
  .use(router)
  .use(LifetimeIframeControl, {
    divId: "app",
    clientConfig: clientConfig,
  })
  // .use(BootstrapVue)
  // .use(IconsPlugin)
  .use(LifetimeHelpers, {
    clientConfig: clientConfig,
  })
  .use(VueGtag, {
    appName: "Pen Underwriting",
    pageTrackerScreenviewEnabled: true,
    config: {
      id: "G-MV1LDD44WJ",
      cookie_flags: "SameSite=None;Secure",
      params: {
        send_page_view: false,
        debug_mode: false,
      },
    },
  })
  .mount("#app");

// Update the page title to the client's name
document.title = clientConfig.content.general.clientName;
