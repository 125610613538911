/* Vue 3 Version */

const LifetimeHelpers = {
  install(Vue) {
    //, options
    //console.log(options) // Only enable this if debugging
    //const clientConfig = options.clientConfig.settings;

    (Vue.config.globalProperties.$formatCurrency = function (
      value,
      decimalVal = false,
      currency = "$"
    ) {
      //console.log(value, typeof value)
      if (value === null || typeof value === "undefined") {
        return "";
      } else if (value < 0) {
        if (decimalVal) {
          return (
            "-" +
            currency +
            Math.abs(value.toFixed(decimalVal))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        } else {
          return (
            "-" +
            currency +
            Math.abs(Math.floor(parseFloat(value)))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        }
      } else if (value >= 0) {
        if (decimalVal) {
          return (
            currency +
            value
              .toFixed(decimalVal)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        } else {
          return (
            currency +
            Math.floor(parseFloat(value))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        }
      }
    }),
      (Vue.config.globalProperties.$roundTo = function (
        input,
        decimalPlaces = 2
      ) {
        //return Math.round(input * 100) / 100;
        return (
          Math.round(input * parseInt("1".padEnd(decimalPlaces + 1, 0))) /
          parseInt("1".padEnd(decimalPlaces + 1, 0))
        );
      });

    Vue.config.globalProperties.$x100 = function (input, decimalPlaces = 5) {
      //return Math.floor(input * 10000) / 100;
      return (
        Math.floor(input * parseInt("1".padEnd(decimalPlaces + 3, 0))) /
        parseInt("1".padEnd(decimalPlaces + 1, 0))
      );
    };

    Vue.config.globalProperties.$div100 = function (input, decimalPlaces = 5) {
      //return Math.floor(input * 100) / 10000;
      return (
        Math.floor(input * parseInt("1".padEnd(decimalPlaces + 1, 0))) /
        parseInt("1".padEnd(decimalPlaces + 3, 0))
      );
    };

    Vue.config.globalProperties.$currentSGRate = function () {
      const today = new Date().getTime();
      if (today < new Date(Date.UTC(2023, 6, 1)).getTime()) {
        return 0.105;
      } else if (today < new Date(Date.UTC(2024, 6, 1)).getTime()) {
        return 0.11;
      } else if (today < new Date(Date.UTC(2025, 6, 1)).getTime()) {
        return 0.115;
      } else {
        return 0.12;
      }
    };

    // Vue.config.globalProperties.$zzz = function() {}
  },
};

export default LifetimeHelpers;
