<script setup>
import { ref, onMounted } from "vue";
import clientConfig from "@/config/client";
import FooterSection from "@/components/FooterSection.vue";
import HeaderSection from "@/components/HeaderSection.vue";
import TeamElement from "../TeamElement.vue";
import PDFElement from "../PDFElement.vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});

const filterDocs = ref("proposal");

defineProps([
  "id",
  "pageName",
  "pageTitle",
  "contactEmail",
  "subHeader",
  "mainbody",
  "teamDivision",
]);
</script>

<template>
  <div :id="`${id}`">
    <HeaderSection class="dark product-page" />

    <main id="main">
      <section class="product-page-layout">
        <div
          class="side-triangle"
          data-aos="zoom-in"
          data-aos-delay="200"
        ></div>
        <header :class="`header-banner d-flex justify-content-start ${id}`">
          <h2 class="highlight">{{ pageTitle }}</h2>
        </header>
        <div class="container" data-aos="zoom-in">
          <h3 class="highlight" v-html="subHeader"></h3>

          <section class="industry pb-0">
            <div class="industry-list row justify-content-left">
              <div class="card col-12">
                <div class="card-body m-0 p-3">
                  <p v-html="mainbody"></p>
                </div>
              </div>
            </div>
          </section>

          <section id="docs" ref="docs" class="docs section-bg">
            <div class="container p-0">
              <div class="section-title">
                <h4 class="card-title highlight my-4">Documents & Forms</h4>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <ul id="docs-flters">
                    <li
                      :class="filterDocs == 'proposal' ? 'filter-active' : ''"
                      @click="filterDocs = 'proposal'"
                    >
                      Proposal Forms
                    </li>
                    <li
                      :class="
                        filterDocs == 'declaration' ? 'filter-active' : ''
                      "
                      @click="filterDocs = 'declaration'"
                    >
                      Renewal Declarations
                    </li>
                    <li
                      :class="filterDocs == 'form' ? 'filter-active' : ''"
                      @click="filterDocs = 'form'"
                    >
                      Questionnaires
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row">
                <PDFElement
                  v-for="pdf in clientConfig.content.docs"
                  :key="pdf"
                  :title="pdf.title"
                  :type="pdf.type"
                  :url="pdf.url"
                  :externalUrl="pdf.externalUrl"
                  v-show="
                    pdf.division == `${teamDivision}` && pdf.type == filterDocs
                  "
                />
              </div>
            </div>
          </section>

          <section>
            <div
              class="underwriters-wrapper product-team row xs-justify-content-start"
            >
              <h4 class="card-title highlight my-4">Meet the Team</h4>
              <TeamElement
                v-for="member in clientConfig.content.people"
                :key="member"
                :name="member.name"
                :division="member.division"
                :role="member.role"
                :mobile="member.mobile"
                :mobileUrl="member.mobileUrl"
                :phone="member.phone"
                :phoneUrl="member.phoneUrl"
                :email="member.email"
                :imageUrl="member.imageUrl"
                :location="member.location"
                v-show="member.division == `${teamDivision}`"
              />
            </div>

            <a class="my-4 purple-button" v-bind:href="'mailto:' + contactEmail"
              >Contact the {{ pageTitle }} Team</a
            >
          </section>
        </div>
      </section>
    </main>

    <FooterSection />
  </div>
</template>
