<script setup>
/* eslint-disable */
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useElementVisibility } from "@vueuse/core";
// import DropdownMenu from "./DropdownMenu.vue";
const showProductSubMenu = ref(null);
const showAboutSubMenu = ref(null);
const showMobileNav = ref(null);
const showMobileSubNav = ref(null);
const routeName = useRoute().fullPath;
console.log(routeName);
const pageTop = ref(null);
const scrolledToPageTop = useElementVisibility(pageTop);
</script>

<template>
  <div ref="pageTop"></div>
  <header
    id="header"
    :class="[
      'navigation',
      scrolledToPageTop ? 'page-top' : 'sticky dark',
      routeName == '/' ? 'homepage' : 'dark',
    ]"
  >
    <div class="container nav">
      <div class="col-2">
        <router-link
          to="/"
          class="logo masthead mr-auto"
          aria-label="Pen Underwriting"
        ></router-link>
      </div>
      <div class="col"></div>
      <div class="col-auto" @mouseleave.prevent="showProductSubMenu = false">
        <div class="top-sub-menu row d-none">
          <div class="sub-menu-item col-auto">
            <router-link
              to="/career"
              :class="routeName == '/career' ? 'selected' : ''"
              >Career</router-link
            >
          </div>
          <span class="col d-none d-xl-inline text-center">|</span>
          <div class="sub-menu-item col-auto">
            <router-link
              to="/contact"
              :class="routeName == '/contact' ? 'selected' : ''"
              >Get In Touch</router-link
            >
          </div>
        </div>
        <div class="row justify-content-end flex main-menu">
          <div
            class="col-auto main-menu-item"
            @mouseover="showAboutSubMenu = true"
            @mouseenter.prevent="
              showAboutSubMenu = true;
              showProductSubMenu = false;
            "
            aria-label="Explore Pen"
          >
            <span :class="showAboutSubMenu == true ? 'revert' : ''"
              >Explore Pen</span
            >
          </div>
          <div
            class="col-auto main-menu-item"
            @mouseover="showProductSubMenu = true"
            @mouseenter.prevent="
              showProductSubMenu = true;
              showAboutSubMenu = false;
            "
          >
            <span :class="showProductSubMenu == true ? 'revert' : ''"
              >Products</span
            >
          </div>
          <div class="col-auto main-menu-item">
            <router-link
              to="/team"
              :class="routeName == '/team' ? 'selected' : ''"
              @mouseenter.prevent="
                showAboutSubMenu = false;
                showProductSubMenu = false;
              "
              aria-label="Our Team"
              >Our Team</router-link
            >
          </div>
          <div class="col-auto main-menu-item">
            <router-link
              to="/claims"
              :class="routeName == '/claims' ? 'selected' : ''"
              @mouseenter.prevent="
                showAboutSubMenu = false;
                showProductSubMenu = false;
              "
              aria-label="Claims"
              >Claims</router-link
            >
          </div>
          <div class="col-auto main-menu-item">
            <router-link
              to="/important-information"
              :class="routeName == '/important-information' ? 'selected' : ''"
              @mouseenter.prevent="
                showAboutSubMenu = false;
                showProductSubMenu = false;
              "
              aria-label="Important Information"
              >Important Info</router-link
            >
          </div>
          <div class="col-auto main-menu-item">
            <router-link
              to="/contact"
              :class="routeName == '/contact' ? 'selected' : ''"
              @mouseenter.prevent="
                showAboutSubMenu = false;
                showProductSubMenu = false;
              "
              aria-label="Get In Touch"
              >Get In Touch</router-link
            >
          </div>
        </div>

        <div
          class="nav-dropdown narrow"
          :style="
            showAboutSubMenu
              ? 'opacity: 1;'
              : 'opacity: 0; pointer-events: none;'
          "
          data-aos="fade-down"
          @mouseleave.prevent="showAboutSubMenu = false"
        >
          <div class="wrapper">
            <div>
              <div>
                <h5>
                  <router-link class="about-link" to="/about-pen#about-pen"
                    >About Us</router-link
                  >
                </h5>
              </div>
              <div>
                <h5>
                  <router-link
                    class="about-link"
                    to="/about-pen#leadership-anchor"
                    >Leadership Team</router-link
                  >
                </h5>
              </div>
              <div>
                <h5>
                  <router-link class="about-link" to="/about-pen#values-anchor"
                    >Our Values</router-link
                  >
                </h5>
              </div>
              <div>
                <h5>
                  <router-link class="about-link" to="/about-pen#giving-anchor"
                    >Giving Back</router-link
                  >
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div
          class="nav-dropdown narrow"
          :style="
            showProductSubMenu
              ? 'opacity: 1;'
              : 'opacity: 0; pointer-events: none;'
          "
          data-aos="fade-down"
          @mouseleave.prevent="showProductSubMenu = false"
        >
          <div class="wrapper">
            <div class="row">
              <div class="col">
                <router-link to="/construction-overview">
                  <div
                    class="product-link"
                    :class="
                      routeName == '/construction-overview'
                        ? 'product-link--selected'
                        : ''
                    "
                  >
                    <h5>Construction</h5>
                    <p>
                      Our broad and evolving appetite caters to the diversity of
                      the Australian Construction Industry.
                    </p>
                  </div>
                </router-link>
                <router-link to="/property-overview">
                  <div
                    class="product-link"
                    :class="
                      routeName == '/property-overview'
                        ? 'product-link--selected'
                        : ''
                    "
                  >
                    <h5>Property</h5>
                    <p>
                      Tailored coverage and competitive pricing for complex
                      medium hazard risks.
                    </p>
                  </div>
                </router-link>
              </div>

              <div class="col">
                <router-link to="/transport-overview">
                  <div
                    class="product-link"
                    :class="
                      routeName == '/transport-overview'
                        ? 'product-link--selected'
                        : ''
                    "
                  >
                    <h5>Transport</h5>
                    <p>From hire cars to commercial long hauls fleets.</p>
                  </div>
                </router-link>
                <router-link to="/general-liability-overview">
                  <div
                    class="product-link"
                    :class="
                      routeName == '/general-liability-overview'
                        ? 'product-link--selected'
                        : ''
                    "
                  >
                    <h5>Liability</h5>
                    <p>Broad appetite for underwriting complex occupations</p>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/professional-risks">
                  <div
                    class="product-link"
                    :class="
                      routeName == '/professional-risks'
                        ? 'product-link--selected'
                        : ''
                    "
                  >
                    <h5>Financial Lines</h5>
                    <p>
                      We have an extensive appetite, accommodating SME risks
                      through to large corporate accounts.
                    </p>
                  </div>
                </router-link>
                <router-link to="/overview-care-liability">
                  <div
                    class="product-link"
                    :class="
                      routeName == '/overview-care-liability'
                        ? 'product-link--selected'
                        : ''
                    "
                  >
                    <h5>Care Liability</h5>
                    <p>
                      Addressing the needs of the Aged and Disability /
                      Community Care sector, offering broader coverage with
                      competitive pricing.
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      @click="showMobileNav = !showMobileNav"
      :class="showMobileNav ? 'mobile-navigation-button--active' : ''"
      class="mobile-navigation-button"
      aria-label="Main menu"
      tabindex="0"
    ></button>
    <div
      class="mobile-navigation-content"
      :class="showMobileNav ? 'mobile-navigation-content--active' : ''"
    >
      <div class="row mobile-menu-layout">
        <div class="col">
          <li class="item" :class="routeName == '/' ? 'selected' : ''">
            <router-link to="/" class="item-link">Home</router-link>
          </li>
          <li class="item" :class="routeName == '/about-pen' ? 'selected' : ''">
            <router-link to="/about-pen" class="item-link"
              >Explore Pen</router-link
            >
          </li>
          <li class="item" :class="routeName == '/team' ? 'selected' : ''">
            <router-link to="/team" class="item-link">Our team</router-link>
          </li>
          <li class="item" :class="routeName == '/claims' ? 'selected' : ''">
            <router-link to="/claims" class="item-link">Claims</router-link>
          </li>
          <li
            class="item"
            :class="routeName == '/important-information' ? 'selected' : ''"
          >
            <router-link to="/important-information" class="item-link"
              >Important Information</router-link
            >
          </li>
          <li class="item" :class="routeName == '/career' ? 'selected' : ''">
            <router-link to="/career" class="item-link">Career</router-link>
          </li>
          <li
            class="item"
            :class="routeName == '/contact' ? 'selected' : ''"
          >
            <router-link to="/contact" class="item-link"
              >Get In Touch</router-link
            >
          </li>
        </div>
        <div class="col">
          <div class="wrap">
            <h2 class="mt-3">Product & Services</h2>
            <li class="item item--has-dropdown item--products">
              <ul
                class="mobile-navigation-subnav mobile-navigation-subnav--show"
              >
                <li
                  class="item"
                  :class="routeName == '/property-overview' ? 'selected' : ''"
                >
                  <router-link to="/property-overview" class="item-link"
                    >Property</router-link
                  >
                </li>
                <li
                  class="item"
                  :class="
                    routeName == '/construction-overview' ? 'selected' : ''
                  "
                >
                  <router-link to="/construction-overview" class="item-link"
                    >Construction</router-link
                  >
                </li>
                <li
                  class="item"
                  :class="routeName == '/transport-overview' ? 'selected' : ''"
                >
                  <router-link to="/transport-overview" class="item-link"
                    >Transport</router-link
                  >
                </li>
                <li
                  class="item"
                  :class="routeName == '/professional-risks' ? 'selected' : ''"
                >
                  <router-link to="/professional-risks" class="item-link"
                    >Financial Lines</router-link
                  >
                </li>
                <li
                  class="item"
                  :class="routeName == '/general-liability-overview' ? 'selected' : ''"
                >
                  <router-link to="/general-liability-overview" class="item-link"
                    >Liability</router-link
                  >
                </li>
                <li
                  class="item"
                  :class="routeName == '/overview-care-liability' ? 'selected' : ''"
                >
                  <router-link to="/overview-care-liability" class="item-link"
                    >Care Liability</router-link
                  >
                </li>
              </ul>
            </li>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
