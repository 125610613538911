<template>
  <router-view></router-view>
</template>

<script>
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
export default {
  setup() {
    const siteData = reactive({
      title: `PEN UNDERWRITING`,
      url: `https://www.penunderwriting.com.au`,
      card: `summary`,
      type: `website`,
      description: `Pen Underwriting is an insurance underwriting agency delivering bespoke solutions. We inspire loyalty and long-standing relationships with all our brokers.`,
    });
    useHead({
      // Meta tag
      title: computed(() => siteData.title),
      meta: [
        { name: `description`, content: computed(() => siteData.description) },
        {
          name: `twitter:description`,
          content: computed(() => siteData.description),
        },
        { name: `twitter:title`, content: computed(() => siteData.title) },
        { name: `twitter:url`, content: computed(() => siteData.url) },
        { name: `twitter:card`, content: computed(() => siteData.card) },
        { property: `og:site_name`, content: computed(() => siteData.title) },
        { property: `og:title`, content: computed(() => siteData.title) },
        { property: `og:url`, content: computed(() => siteData.url) },
        { property: `og:type`, content: computed(() => siteData.type) },
        {
          property: `og:description`,
          content: computed(() => siteData.description),
        },
        { itemprop: `name`, content: computed(() => siteData.title) },
        { itemprop: `url`, content: computed(() => siteData.url) },
        {
          itemprop: `description`,
          content: computed(() => siteData.description),
        },
      ],
    });
  },
  name: "App",
};
</script>

<style lang="scss">
@import "~@/scss/main.scss";
</style>
