<template>
  <footer id="footer" ref="contacts">
    <div
      class="side-triangle-footer"
      data-aos="zoom-in"
      data-aos-delay="200"
    ></div>

    <div class="container row pb-0">
      <div class="col">
        <img
          src="@/assets/img/pen/pen_w_logo.png"
          alt="Pen Underwriting is an insurance underwriting agency delivering bespoke solutions."
        />
      </div>
      <div class="col col-lg-7">
        <div class="acknowledgement">
          <p>
            <strong>Acknowledgement of Country</strong> - Pen Underwriting
            acknowledges Traditional Owners of Country throughout Australia and
            recognises the continuing connection to lands, waters and
            communities. We pay our respect to Aboriginal and Torres Strait
            Islander cultures; and to Elders past and present.
          </p>
        </div>
      </div>
    </div>
    <div class="container row pb-4">
      <div class="credits col-7">
        <div class="row">
          <div class="col-auto">
            <router-link to="/contact">Get In Touch</router-link>
          </div>
          <div class="col-auto">
            <a
              href="./forms/proposals/important_informations/PEN-CMP004e+Complaints+and+Dispute+Resolution+Policy+v1123+Approved+202311.pdf"
              target="_blank"
              >Complaints Policy</a
            >
          </div>
          <div class="col-auto">
            <a
              href="./forms/proposals/important_informations/PEN-CMP005e+Privacy+Notice+incl+Addendum+v0124+Approved.pdf"
              target="_blank"
              >Privacy Notice</a
            >
          </div>
          <div class="col-auto">
            <router-link to="/career">Career</router-link>
          </div>
          <div class="col-12 col-md-auto">
            <div>
              <div class="row">
                <div class="col-auto social-link">
                  <a
                    href="https://au.linkedin.com/company/pen-underwriting---australia"
                    target="_blank"
                    ><img
                      class="social-icon"
                      src="@/assets/img/pen/pen-underwriting-linkedin.png"
                      alt="Pen Underwriting Australia Linkedin"
                  /></a>
                </div>
                <div class="col-auto social-link">
                  <a
                    href="https://www.instagram.com/penunderwriting/"
                    target="_blank"
                    ><img
                      class="social-icon"
                      src="@/assets/img/pen/pen-underwriting-instagram.png"
                      alt="Pen Underwriting Australia Instagram"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="copyright col-5">
        <p>
          &copy; Copyright Pen Underwriting Pty Ltd
          {{ new Date().getFullYear() }}
        </p>
        <p>ABN 89 113 929 516 | AFSL 290518</p>
      </div>
    </div>
  </footer>
</template>
